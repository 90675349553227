<template>
  <v-card class="mt-4">
    <v-card-title>Update SurveyGizmo Response</v-card-title>

    <v-card-text>
      <ul>
        <li>Upload a file with <code>.csv</code>extension and at max 1000 rows</li>
        <li>The column that must exists are <code>surveyId</code>, <code>responseId</code>, and <code>newStatus</code>. The column headers are case sensitive!</li>
        <li>The only accepted <code>newStatus</code> values are <code>Complete</code>, <code>Partial</code>, <code>Disqualified</code>. The values are case sensitive!</li>
        <li>Update response status might takes time to execute as it is overwritten one by one</li>
        <li>If it is unable to update a specific response, the operation will be stopped. Previously updated response are not effected.</li>
        <li>Update progress can be found under the update button when it started. Do not refresh the page until it is completed OR it is stuck.</li>
      </ul>

      <v-form ref="form">
        <v-file-input
          ref="inputFile"
          label="Upload File"
          persistent-hint
          hint="The format of the file should be csv. Be sure to read the instructions above!"
          accept=".csv"
          @change="selectFile"
          :rules="[v => !!v || 'File is required']"
        />
      </v-form>

      <v-btn class="primary mt-3" @click="updateResponse" :disabled="this.toSendData.responses.length == 0">Update</v-btn>
      
      <div v-if="$store.state.loader == true" class="pt-2">Updating {{ counter }}/{{ this.toSendData.responses.length }}...</div>
    </v-card-text>
  </v-card>
</template>

<script>
var csvjson = require("csvjson");

import RestResource from "@/services/dataServiceBuyer.js";

const service = new RestResource();

export default {
  inject: {
    $validator: '$validator'
  },
  data() {
    return {
      counter: 0,
      toSendData: {
        type: "UPDATE_SURVEY_STATUS",
        responses: [],
      }
    };
  },

  methods: {
    async updateResponse() {
      if (this.$refs.form.validate()) {
        this.$setLoader()

        this.toSendData.responses = this.processResponses(this.toSendData.responses)

        for (let i = 0; i < this.toSendData.responses.length; i++) {
          this.counter = i + 1

          try {
            await service.surveyGizmoProxyPass({
              type: this.toSendData.type,
              assignedBy: this.$store.state.userProfile.name,
              ...this.toSendData.responses[i]
            })
          } catch (error) {
            this.$refs.inputFile.reset()
            this.toSendData.responses = []
            throw new Error(`Failed to update row ${i} (exclude header). Potentially response ID does not exists.`)
          }
        }
        
        this.$showSuccessMessage({ message: `Succesfully updated ${this.toSendData.responses.length} responses` })
        
        this.$refs.inputFile.reset()
        this.toSendData.responses = []
      }
    },

    selectFile(e) {
      if (e) {
        const self = this;
        const file = e;
        this.buttonText = file.name;

        let reader = new FileReader();
        reader.onload = () => {
          self.importedData = reader.result;

          this.toSendData.responses = csvjson.toObject(reader.result, {});
        };
        reader.readAsText(file);
      }
    },

    processResponses(csvData) {
      let responses = [];

      for (let i = 0; i < csvData.length; i++) {
        if (csvData[i].surveyId != null && csvData[i].surveyId != undefined && csvData[i].surveyId != "" &&
            csvData[i].responseId != null && csvData[i].responseId != undefined && csvData[i].responseId != "" &&
            csvData[i].newStatus != null && csvData[i].newStatus != undefined && ["Complete", "Partial", "Disqualified"].includes(csvData[i].newStatus)) {
          
          responses.push({
            surveyId: csvData[i].surveyId.trim(),
            responseId: csvData[i].responseId.trim(),
            newStatus: csvData[i].newStatus.trim()
          });
        } else {
          this.$refs.inputFile.reset()
          this.toSendData.responses = []
          throw new Error(`Error at row ${i + 1} (exclude header). Possibility missing required fields or wrong status`)
        }
      }

      return Array.from(new Set(responses));
    }
  }
};
</script>